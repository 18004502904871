<template>
<div class="checkbox-container">
    <div class="container-header">
      <h3>{{ title }}</h3>
      <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
        <ChevronUp />
      </div>
    </div>

    <div class="checkboxes" v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
        <div v-for="checkbox in checkboxes" :key="checkbox" class="check">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="4" fill="#D9DFF2"/>
            <path d="M6 12L10 16L19 7" stroke="#A4B1DF"/>
            </svg>
            <p>{{ checkbox}}</p>
        </div>
    </div>
</div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'

export default {
    components: { ChevronUp },

    props: {
        title: String,
        checkboxes: [],
    },

    data() {
      return{
        open: true,
      }
    },

    methods: {
      handleOpen(){
        this.open = !this.open
      }
    },
}
</script>

<style lang="scss" scoped>
.checkbox-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;

    h3 {
      font-size: 18px;
      color: var(--dark-blue);
      font-weight: 600;
    }

    .chevron {
      width: 25px;
      height: 25px;

      > svg {
        cursor: pointer;
        stroke: var(--neutral-600);
        transition: .5s;
      }
    }

    .rotateUp {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    .rotateDown {
        animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

    }

    .checkboxes {
        display: flex;
        flex-direction: row;
        justify-content: start;

        .check {
            display: flex;
            flex-direction: row;
            align-items: center;

            svg {
                margin-right: 10px;
            }

            p {
                margin-right: 20px;
            }
        }
    }
}
</style>